<template>
  <div>
    <!-- Sample Active - Filter (for mobile view only) -->
    <section class="d-block d-sm-none">
      <div class="mb-3">
        <v-select
          outlined
          dense
          v-model="filter.company"
          single-line
          :items="activeCompany"
          item-text="name"
          item-value="id"
          :placeholder="$t('content.select_company')"
          hide-details
          background-color="#fff"
          class="rounded-lg"
          clearable
        />
      </div>
      <div class="mb-3">
        <v-text-field
          outlined
          dense
          v-model="filter.idflNumber"
          :placeholder="`${$t('content.search_idfl')}...`"
          single-line
          class="rounded-lg"
          :hide-details="true"
          clearable
          background-color="#fff"
        />
      </div>
      <div class="mb-3">
        <v-text-field
          outlined
          dense
          v-model="filter.sampleId"
          :placeholder="`${$t('content.search_sample_id')}...`"
          single-line
          class="rounded-lg"
          :hide-details="true"
          clearable
          background-color="#fff"
        />
      </div>
      <div class="mb-3">
        <v-select
          outlined
          dense
          v-model="filter.sampleType"
          single-line
          :items="sampleTypeActive"
          item-text="name"
          item-value="id"
          :placeholder="$t('content.select_type')"
          :hide-details="true"
          background-color="#fff"
          class="rounded-lg"
          clearable
        />
      </div>
      <div class="mb-3">
        <date-picker
          v-model="filter.arrivedAt"
          format="YYYY-MM-DD"
          range
          value-type="format"
          :disabled-date="utilityMixin_disabledDateMaxNow"
          :clearable="false"
          style="width: 100%"
        >
          <i slot="icon-calendar"></i>
          <template #input>
            <v-text-field
              outlined
              dense
              single-line
              :placeholder="$t('content.select_date')"
              readonly
              v-model="filter.arrivedAt"
              :hide-details="true"
              class="rounded-lg"
              background-color="#fff"
              clearable
            >
              <svg-icon icon="icon-calendar" slot="append" class="mt-1" />
            </v-text-field>
          </template>
        </date-picker>
      </div>
    </section>

    <SampleActiveTabs v-model="filter.stage" />

    <v-data-table
      dense
      hide-default-footer
      :loading="loading"
      :headers="table.headers"
      :items="sampleActive.results"
      :server-items-length="sampleActive.count"
      item-key="id"
      class="custom-table"
    >
      <template #[`header.company`]="{ header }">
        {{ header.text }}
        <div class="mt-2" style="max-width:198px;">
          <v-select
            outlined
            dense
            v-model="filter.company"
            single-line
            :items="activeCompany"
            item-text="name"
            item-value="id"
            :placeholder="$t('content.select_company')"
            hide-details
            background-color="#fff"
            class="rounded-lg"
            clearable
          />
        </div>
      </template>
      <template #[`header.idfl_number`]="{ header }">
        {{ header.text }}
        <div class="mt-2">
          <v-text-field
            outlined
            dense
            v-model="filter.idflNumber"
            :placeholder="`${$t('content.search_idfl')}...`"
            single-line
            class="rounded-lg"
            :hide-details="true"
            clearable
            background-color="#fff"
          />
        </div>
      </template>
      <template #[`header.sample_identification`]="{ header }">
        {{ header.text }}
        <div class="mt-2">
          <v-text-field
            outlined
            dense
            v-model="filter.sampleId"
            :placeholder="`${$t('content.search_sample_id')}...`"
            single-line
            class="rounded-lg"
            :hide-details="true"
            clearable
            background-color="#fff"
          />
        </div>
      </template>
      <template #[`header.sample_type`]="{ header }">
        {{ header.text }}
        <div class="mt-2" style="max-width:150px;">
          <v-select
            outlined
            dense
            v-model="filter.sampleType"
            single-line
            :items="sampleTypeActive"
            item-text="name"
            item-value="id"
            :placeholder="$t('content.select_type')"
            :hide-details="true"
            background-color="#fff"
            class="rounded-lg"
            clearable
          />
        </div>
      </template>
      <template #[`header.arrived_at_date`]="{ header }">
        {{ header.text }}
        <div class="mt-2">
          <date-picker
            v-model="filter.arrivedAt"
            format="YYYY-MM-DD"
            range
            value-type="format"
            :disabled-date="utilityMixin_disabledDateMaxNow"
            :clearable="false"
          >
            <i slot="icon-calendar"></i>
            <template #input>
              <v-text-field
                outlined
                dense
                single-line
                :placeholder="$t('content.select_date')"
                readonly
                v-model="filter.arrivedAt"
                :hide-details="true"
                class="rounded-lg"
                background-color="#fff"
                clearable
              >
                <svg-icon icon="icon-calendar" slot="append" class="mt-1" />
              </v-text-field>
            </template>
          </date-picker>
        </div>
      </template>

      <template #[`item.sample_identification`]="{ value }">
        <v-expansion-panels accordion flat v-if="splitSampleId(value).length > 1" class="expansion-title">
          <v-expansion-panel class="expansion-panel">
            <v-expansion-panel-header class="pl-0">
              {{ splitSampleId(value)[0] }}
              <template #actions>
                <v-icon color="blue lighten-1">
                  mdi-menu-down
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pl-0 " v-for="(item, index) in splitSampleId(value).slice(1)" :key="index">
              {{ item }}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-expansion-panels accordion flat v-else>
          <v-expansion-panel class="expansion-panel">
            <v-expansion-panel-header hide-actions class="px-0">
              {{ splitSampleId(value)[0] }}
            </v-expansion-panel-header>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
      <template #[`item.stage`]="{ value }">
        <SampleActiveStatus :status="value" />
      </template>
      <template #[`item.arrived_at_date`]="{ value }">
        {{ utilityMixin_formatDateTime(value) }}
      </template>
      <template #[`item.actions`]="{ item }">
        <BaseActionButton :tooltipsText="$t('content.view_sample')" @click="navigateToDetail(item)">
          <SvgIcon icon="icon-eye" width="20px" height="20px" />
        </BaseActionButton>
        <BaseActionButton :tooltipsText="$t('content.share')" @click="shareSample(item)">
          <SvgIcon icon="icon-share" width="20px" height="20px" />
        </BaseActionButton>
      </template>

      <template #no-data>
        <BaseNoData :message="$t('content.no_sample_data')" />
      </template>
      <template #footer>
        <BaseTableFooter :data="sampleActive" :filter="filter" :params="table.params" @change="onChangeTable" />
      </template>
    </v-data-table>

    <SampleShare :dialog="dialogShare" :idflNumber="selectedActive.idfl_number" @close="closeDialog" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DatePicker from 'vue2-datepicker';
import utilityMixin from '@/mixins/utilityMixin';
import SampleActiveTabs from '@/components/sample-tracking/SampleActiveTabs';
import SampleActiveStatus from '@/components/sample-tracking/SampleActiveStatus';
import SampleShare from '@/components/sample-tracking/SampleShare';
import debounce from 'lodash/debounce';

export default {
  name: 'SampleActive',
  mixins: [utilityMixin],
  components: {
    DatePicker,
    SampleActiveTabs,
    SampleActiveStatus,
    SampleShare,
  },
  data() {
    return {
      filter: {
        arrivedAt: [],
        sampleId: null,
        sampleType: null,
        company: null,
        idflNumber: null,
        stage: 'All Status',
      },
      loading: false,
      dialogShare: false,
      selectedActive: {},
      table: {
        headers: [
          { text: this.$t('content.company'), value: 'company', width: 230, sortable: false },
          { text: this.$t('content.idflNumber'), value: 'idfl_number', width: 230, sortable: false },
          { text: this.$t('content.sample_id'), value: 'sample_identification', width: 400, sortable: false },
          { text: this.$t('content.sample_type'), value: 'sample_type', width: 190, sortable: false },
          { text: this.$t('content.arrival_date'), value: 'arrived_at_date', width: 200, sortable: false },
          { text: this.$t('content.status'), value: 'stage', sortable: false, width: 180 },
          { text: this.$t('content.actions'), value: 'actions', sortable: false, width: 130 },
        ],
        params: { limit: 10 },
      },
      initial: true,
    };
  },
  computed: {
    ...mapGetters({
      sampleTypeActive: 'SampleTracking/sampleTypeActive',
      sampleActive: 'SampleTracking/sampleActive',
      stages: 'SampleTracking/stages',
      activeCompany: 'SampleTracking/activeCompany',
      activeParams: 'SampleTracking/activeParams',
    }),
    arrivedValue() {
      return this.utilityMixin_splitDate(this.filter.arrivedAt, 'arrived_at_after', 'arrived_at_before');
    },
  },
  watch: {
    filter: {
      deep: true,
      handler: debounce(function(filter) {
        this.onChangeTable({
          ...filter,
          ...(!this.initial ? { offset: 0, page: 1 } : null),
        });
      }, 300),
    },
  },
  methods: {
    initialByQuery() {
      const {
        query: { tab },
      } = this.$route;

      if (this.activeParams) {
        if (tab !== 'active') return;
        const params = this.activeParams;

        this.table.params = {
          ...this.table.params,
          limit: params?.limit,
          offset: params?.offset,
          page: params?.page,
        };

        this.filter = {
          ...this.filter,
          arrivedAt: params?.arrivedAt,
          sampleId: params?.sampleId,
          sampleType: params?.sampleType,
          company: params?.company,
          idflNumber: params?.idflNumber,
          stage: params.stage,
        };
      } else {
        this.fetchData();
      }
    },
    fetchData() {
      this.loading = true;

      const params = {
        ...this.table.params,
        ...this.utilityMixin_mappingValue(this.filter, {
          sampleId: 'search',
          sampleType: 'sample_type',
          idflNumber: 'idfl_number',
        }),
        stage: this.filter.stage === 'All Status' ? null : this.filter.stage,
        ...this.arrivedValue,
      };

      this.$store
        .dispatch('SampleTracking/getSampleTrackingActive', params)
        .catch(err => this.$toast.error(err.message))
        .finally(() => {
          this.loading = false;
          this.initial = false;
        });
    },
    onChangeTable(params = {}) {
      this.table.params = {
        ...this.table.params,
        ...params,
      };
      this.fetchData();
    },
    shareSample(val) {
      this.loading = true;
      this.selectedActive = val;
      const { id: object_id, content_type } = val;
      const param = { object_id, content_type };
      this.$store.dispatch('SampleTracking/getSampleDetail', val.id);
      this.$store
        .dispatch('SampleTracking/getWorkOrderOwner', param)
        .then(() => (this.dialogShare = true))
        .catch(err => this.$toast.error(err.message))
        .finally(() => (this.loading = false));
    },
    closeDialog() {
      this.dialogShare = false;
      this.selectedActive = {};
    },
    splitSampleId(input) {
      return input.split('\r\n');
    },
    navigateToDetail(item) {
      this.$router.push({
        name: 'SampleDetail',
        params: {
          mode: 'active',
          id: item.id,
        },
      });
    },
  },
  created() {
    this.initialByQuery();
    this.$store.dispatch('SampleTracking/getSampleTypeActive');
  },
};
</script>
