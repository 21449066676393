<template>
  <div class="main-container-btn">
    <v-item-group mandatory v-model="localValue">
      <v-row no-gutters>
        <v-col v-for="status in stages" :key="status.value" class="flex-grow-1" cols="12" sm="auto">
          <v-item v-slot="{ active, toggle }" :value="status.value">
            <v-card
              class="w-100 d-flex align-center justify-center item"
              :class="{
                'stat-1': active && status.value === 'All Status',
                'stat-2': active && status.value === 'Checked In',
                'stat-3': active && status.value === 'In Testing',
                'stat-4': active && status.value === 'Reporting',
                'stat-5': active && status.value === 'Invoiced',
              }"
              light
              height="45"
              @click="toggle"
              flat
              elevation="0"
            >
              {{ $t(status.display) }}
            </v-card>
          </v-item>
        </v-col>
      </v-row>
    </v-item-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SampleActiveTabs',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      stages: 'SampleTracking/stages',
    }),
  },
  data: () => ({
    localValue: '',
  }),
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.localValue = val;
      },
    },
    localValue(value) {
      this.$emit('change', value);
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container-btn {
  background-color: #f2f7fb;
  border-radius: 8px;
  min-height: 40px;
  padding: 8px;

  .container-btn {
    background-color: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 7px;
    font-size: 16px;

    .br-6 {
      border-radius: 6px;
      overflow: hidden;
      font-family: Arial;
    }

    .inactive-text {
      color: #16325c;
      opacity: 0.38;
    }
  }

  .item {
    color: #666 !important;
  }

  .stat-1 {
    background-color: #73acd9;
    color: #fff !important;
  }

  .stat-2 {
    background-color: #d6edff;
    color: #16325c !important;
  }

  .stat-3 {
    background-color: #addcff;
    color: #16325c !important;
  }

  .stat-4 {
    background-color: #85caff;
    color: #16325c !important;
  }

  .stat-5 {
    background-color: #5cb8ff;
    color: #16325c !important;
  }
}
</style>
