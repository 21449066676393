<template>
  <div>
    <v-row>
      <v-col cols="12" md="6" class="d-flex align-center justify-end" order-md="2">
        <v-btn small depressed class="indigo white--text px-4" :loading="loading" @click="exportToExcel">
          {{ $t('content.export_excel') }}
        </v-btn>
      </v-col>
      <v-col cols="12" md="6" order-md="1">
        <v-tabs height="35px" v-model="tab" :grow="$vuetify.breakpoint.smAndDown">
          <v-tab class="custom-tabs" v-for="tabs in tabsItems" :key="tabs">
            {{ tabs }}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>

    <v-tabs-items v-model="tab">
      <v-tab-item class="pt-4">
        <SampleActive />
      </v-tab-item>
      <v-tab-item class="pt-4">
        <SampleHistory />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { utilityMixin, downloadMixin } from '@/mixins';
import SampleActive from '@/components/sample-tracking/SampleActive';
import SampleHistory from '@/components/sample-tracking/SampleHistory';

export default {
  name: 'SampleTracking',
  mixins: [utilityMixin, downloadMixin],
  components: {
    SampleActive,
    SampleHistory,
  },
  data() {
    return {
      loading: false,
      tab: 0,
      tabsItems: [this.$t('content.active'), this.$t('content.history')],
    };
  },
  computed: {
    ...mapGetters({
      activeParams: 'SampleTracking/activeParams',
      historicalParams: 'SampleTracking/historicalParams',
    }),
  },
  watch: {
    '$route.query': {
      immediate: true,
      deep: true,
      handler(query) {
        this.tab = query.tab === 'historical' ? 1 : 0;
      },
    },
    tab: {
      handler(val) {
        this.$router
          .push({
            query: {
              tab: val === 0 ? 'active' : 'historical',
            },
          })
          .catch(() => {});
      },
    },
  },
  methods: {
    exportToExcel() {
      this.loading = true;
      this.$store
        .dispatch('File/getData', {
          data:
            this.tab === 0
              ? '/sample_workbench/api/samples/current/export_excel/'
              : '/sample_workbench/api/samples/historical/export_excel/',
          params: this.tab === 0 ? this.activeParams : this.historicalParams,
        })
        .then(blob => {
          this.downloadMixin_download(blob, {
            name: `IDFL ${this.tab === 0 ? 'Current Samples' : 'Historical Samples'} ${this.utilityMixin_formatSDateTime(
              new Date(),
            )}.xlsx`,
          });
        })
        .finally(() => (this.loading = false));
    },
  },
  created() {
    this.$store.dispatch('SampleTracking/getActiveCompany');
    this.$store.dispatch('ResultManagement/getAssociatedCompanies');
  },
  beforeRouteEnter(_, from, next) {
    next(vm => {
      if (from.name !== 'SampleDetail') {
        vm.$store.commit('SampleTracking/SET_PARAMS_ACTIVE', null);
        vm.$store.commit('SampleTracking/SET_PARAMS_HISTORICAL', null);
        vm.$store.commit('SampleTracking/RESET_SAMPLE_ACTIVE');
        vm.$store.commit('SampleTracking/RESET_SAMPLE_HISTORICAL');
      }
    });
  },
  beforeRouteUpdate(_, __, next) {
    next({ query: { maman: 'dorman' }, replace: true });
  },
  beforeRouteLeave(to, _, next) {
    if (to.name !== 'SampleDetail') {
      this.$store.commit('SampleTracking/SET_PARAMS_ACTIVE', null);
      this.$store.commit('SampleTracking/SET_PARAMS_HISTORICAL', null);
      this.$store.commit('SampleTracking/RESET_SAMPLE_ACTIVE');
      this.$store.commit('SampleTracking/RESET_SAMPLE_HISTORICAL');
    }
    next();
  },
  destroyed() {
    this.$store.commit('SampleTracking/RESET_SAMPLE_DETAIL');
  },
};
</script>

<style lang="scss" scoped>
/* .tabs {
  font-family: ArialMT;
  font-size: 15px;
  font-weight: bold;
  padding: 0 30px 0 33px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  overflow: hidden;
} */
</style>
