<template>
  <div v-if="status" class="my-1">
    <v-row dense no-gutters>
      <v-col cols="12" class="status-title">
        {{ $t(statusTitle(status)) }}
      </v-col>
    </v-row>
    <v-row dense no-gutters>
      <v-col cols="12">
        <SvgIcon isResponsive icon="tracking-status/1-checked-in" v-if="status === 'Checked In'" />
        <SvgIcon isResponsive icon="tracking-status/2-in-testing" v-else-if="status === 'In Testing'" />
        <SvgIcon isResponsive icon="tracking-status/3-reporting" v-else-if="status === 'Reporting'" />
        <SvgIcon isResponsive icon="tracking-status/4-invoiced" v-else-if="status === 'Invoiced'" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SampleActiveStatus',
  props: {
    status: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      stages: 'SampleTracking/stages',
    }),
  },
  methods: {
    statusTitle(text) {
      const sts = this.stages.find(stage => stage.value === text);
      if (sts) return sts.display;
      else return text;
    },
  },
};
</script>

<style lang="scss" scoped>
.status-title {
  font-size: 12px;
  color: #0060af;
}
</style>
